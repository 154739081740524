var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "container-register-inner", attrs: { fluid: "" } },
        [
          _c(
            "div",
            [
              _c("b-container", [
                _c("div", { staticClass: "title-register mt-1" }, [
                  _vm._v(_vm._s(_vm.$t("register.title")))
                ]),
                _c("div", { staticClass: "subtitle-register mt-0" }, [
                  _vm._v(" " + _vm._s(_vm.$t("register.subtitle")) + " ")
                ]),
                _c(
                  "form",
                  {
                    attrs: { action: "" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.register()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.idNameGroup,
                              expression: "idNameGroup"
                            }
                          ],
                          staticClass: "input-text",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("register.form.idNameGroup"),
                            "data-cy": "id-name-group"
                          },
                          domProps: { value: _vm.idNameGroup },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.idNameGroup = $event.target.value
                            }
                          }
                        }),
                        _c("i", {
                          staticClass: "fa fa-info-circle iconInfo",
                          attrs: { id: "idNameGroupTooltip" }
                        }),
                        _c("CpTooltipDynamic", {
                          attrs: {
                            target: "idNameGroupTooltip",
                            title: "idNameGroupTooltip",
                            paddingContainer: "10px 5px 10px 5px",
                            message: _vm.$t("register.form.toolTipIdNameGroup"),
                            width: 300,
                            height: 100
                          }
                        }),
                        _c("span", { staticClass: "input__detail" }, [
                          _vm._v(
                            _vm._s(_vm.$t("register.form.idNameGroupDetail"))
                          )
                        ]),
                        _c("br"),
                        _vm.idNameGroup
                          ? _c(
                              "span",
                              {
                                staticClass: "input__count",
                                class: _vm.countIdNameGroupClass
                              },
                              [
                                _vm._v(
                                  "(" +
                                    _vm._s(_vm.idNameGroup.length || 0) +
                                    "/16)"
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    !_vm.$v.idNameGroup.required && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("register.form.idNameGroupRequired")
                              ) +
                              " "
                          )
                        ])
                      : !_vm.goodIdNameGroup && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.goodIdNameGroup1")) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.goodIdNameGroup2")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name"
                          }
                        ],
                        staticClass: "input-text",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("register.form.name"),
                          "data-cy": "name"
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          }
                        }
                      })
                    ]),
                    !_vm.$v.name.required && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.nameRequired")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user,
                              expression: "user"
                            }
                          ],
                          staticClass: "input-text",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("register.form.user"),
                            "data-cy": "user"
                          },
                          domProps: { value: _vm.user },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.user = $event.target.value
                            }
                          }
                        }),
                        _c("i", {
                          staticClass: "fa fa-info-circle iconInfo",
                          attrs: { id: "userTooltip" }
                        }),
                        _c("CpTooltipDynamic", {
                          attrs: {
                            target: "userTooltip",
                            title: "userTooltip",
                            paddingContainer: "10px 5px 10px 5px",
                            message: _vm.$t("register.form.toolTipUser"),
                            width: 300,
                            height: 100
                          }
                        }),
                        _vm.user
                          ? _c(
                              "span",
                              {
                                staticClass: "input__count",
                                class: _vm.countUserClass
                              },
                              [
                                _vm._v(
                                  "(" + _vm._s(_vm.user.length || 0) + "/16)"
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    !_vm.$v.user.required && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.userRequired")) +
                              " "
                          )
                        ])
                      : !_vm.goodUser && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.goodUser1")) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.goodUser2")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email"
                          }
                        ],
                        staticClass: "input-text",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t("register.form.email"),
                          "data-cy": "email"
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.email = $event.target.value
                          }
                        }
                      })
                    ]),
                    !_vm.$v.email.required && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.emailRequired")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    !_vm.$v.email.email && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.emailValid")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      staticClass: "input-text",
                      attrs: {
                        type: "password",
                        placeholder: _vm.$t("register.form.password"),
                        "data-cy": "password"
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    }),
                    _c("i", {
                      staticClass: "fa fa-info-circle iconInfo",
                      attrs: { id: "passwordTooltip" }
                    }),
                    _c("CpTooltipDynamic", {
                      attrs: {
                        target: "passwordTooltip",
                        title: "passwordTooltip",
                        paddingContainer: "10px 5px 10px 5px",
                        message: _vm.$t("register.form.toolTipPassword"),
                        width: 300,
                        height: 100
                      }
                    }),
                    _vm.password
                      ? _c(
                          "span",
                          {
                            staticClass: "input__count",
                            class: _vm.countPasswordClass
                          },
                          [_vm._v("(" + _vm._s(_vm.password.length || 0) + ")")]
                        )
                      : _vm._e(),
                    !_vm.goodPassword && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.goodPassword1")) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.goodPassword2")) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.goodPassword3")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.confirmPassword,
                            expression: "confirmPassword"
                          }
                        ],
                        staticClass: "input-text",
                        attrs: {
                          type: "password",
                          placeholder: _vm.$t("register.form.confirmPassword"),
                          "data-cy": "new-password"
                        },
                        domProps: { value: _vm.confirmPassword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmPassword = $event.target.value
                          }
                        }
                      })
                    ]),
                    !_vm.$v.confirmPassword.sameAs && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.samePassword")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.countrySelected,
                              expression: "countrySelected"
                            }
                          ],
                          staticClass: "input-select",
                          attrs: { "data-cy": "country" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.countrySelected = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { hidden: "", selected: "" },
                              domProps: { value: null }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("register.form.selectcountry")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm._l(_vm.countries, function(country, key) {
                            return _c(
                              "option",
                              { key: key, domProps: { value: country.value } },
                              [_vm._v(" " + _vm._s(country.text) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    !_vm.$v.countrySelected.required && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.countryRequired")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", { staticStyle: { width: "46%" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.city,
                              expression: "city"
                            }
                          ],
                          staticClass: "input-city",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("register.form.city"),
                            "data-cy": "city"
                          },
                          domProps: { value: _vm.city },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.city = $event.target.value
                            }
                          }
                        })
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.code,
                              expression: "code"
                            }
                          ],
                          staticClass: "input-code",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("register.form.code"),
                            "data-cy": "code"
                          },
                          domProps: { value: _vm.code },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.code = $event.target.value
                            }
                          }
                        }),
                        !_vm.$v.code.integer && _vm.registerPressed
                          ? _c("div", { staticClass: "error" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("register.form.isCodePostalNumber")
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.timezoneSelected,
                              expression: "timezoneSelected"
                            }
                          ],
                          staticClass: "input-select",
                          attrs: { "data-cy": "timezone" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.timezoneSelected = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { hidden: "", selected: "" },
                              domProps: { value: null }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("register.form.selectzonatime")
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm._l(_vm.timezones, function(timezone, key) {
                            return _c(
                              "option",
                              { key: key, domProps: { value: timezone.value } },
                              [_vm._v(" " + _vm._s(timezone.text) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    !_vm.$v.timezoneSelected.required && _vm.registerPressed
                      ? _c("div", { staticClass: "error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("register.form.timezoneRequired")) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "button-register mt-1",
                            attrs: { type: "submit", "data-cy": "submit" }
                          },
                          [
                            _vm.loading
                              ? _c(
                                  "span",
                                  [
                                    _c("b-spinner", {
                                      staticClass: "spinner-btn"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("register.form.load")) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("register.form.register")) +
                                      " "
                                  )
                                ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container-bottom-text" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("register.msg1")) + " "),
                    _c(
                      "router-link",
                      { staticClass: "link-login", attrs: { to: "/login" } },
                      [_vm._v(_vm._s(_vm.$t("register.link1")))]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ]
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
          refresh: true
        },
        on: { sendResult: _vm.registerConfirm },
        model: {
          value: _vm.showAlert,
          callback: function($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }