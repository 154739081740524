<template>
  <div>
    <b-container fluid class="container-register-inner">
      <div>
        <b-container>
          <div class="title-register mt-1">{{ $t("register.title") }}</div>
          <div class="subtitle-register mt-0">
            {{ $t("register.subtitle") }}
          </div>
          <form action="" @submit.prevent="register()">
            <div>
              <input
                type="text"
                class="input-text"
                v-model="idNameGroup"
                :placeholder="$t('register.form.idNameGroup')"
                data-cy="id-name-group"
              />
              <i id="idNameGroupTooltip" class="fa fa-info-circle iconInfo"></i>
              <CpTooltipDynamic
                target="idNameGroupTooltip"
                title="idNameGroupTooltip"
                paddingContainer="10px 5px 10px 5px"
                :message="$t('register.form.toolTipIdNameGroup')"
                :width="300"
                :height="100"
              />
              <span class="input__detail">{{ $t('register.form.idNameGroupDetail') }}</span><br>
              <span v-if="idNameGroup" class="input__count" :class="countIdNameGroupClass">({{ idNameGroup.length || 0 }}/16)</span>
            </div>

            <div class="error" v-if="!$v.idNameGroup.required && registerPressed">
              {{ $t("register.form.idNameGroupRequired") }}
            </div>
            <div class="error" v-else-if="!goodIdNameGroup && registerPressed ">
              {{ $t("register.form.goodIdNameGroup1") }} <br>
              {{ $t("register.form.goodIdNameGroup2") }}
            </div>
            <div>
              <input
                type="text"
                class="input-text"
                v-model="name"
                :placeholder="$t('register.form.name')"
                data-cy="name"
              />
            </div>
            <div class="error" v-if="!$v.name.required && registerPressed">
              {{ $t("register.form.nameRequired") }}
            </div>
            <div>
              <input
                type="text"
                class="input-text"
                v-model="user"
                :placeholder="$t('register.form.user')"
                data-cy="user"
              />
              <i id="userTooltip" class="fa fa-info-circle iconInfo"></i>
              <CpTooltipDynamic
                target="userTooltip"
                title="userTooltip"
                paddingContainer="10px 5px 10px 5px"
                :message="$t('register.form.toolTipUser')"
                :width="300"
                :height="100"
              />
              <span v-if="user" class="input__count" :class="countUserClass">({{ user.length || 0 }}/16)</span>
            </div>
            <div class="error" v-if="!$v.user.required && registerPressed">
              {{ $t("register.form.userRequired") }}
            </div>
            <div class="error" v-else-if="!goodUser && registerPressed ">
              {{ $t("register.form.goodUser1") }} <br>
              {{ $t("register.form.goodUser2") }}
            </div>
            <div>
              <input
                type="text"
                class="input-text"
                v-model="email"
                :placeholder="$t('register.form.email')"
                data-cy="email"
              />
            </div>
            <div class="error" v-if="!$v.email.required && registerPressed">
              {{ $t("register.form.emailRequired") }}
            </div>
            <div class="error" v-if="!$v.email.email && registerPressed">
              {{ $t("register.form.emailValid") }}
            </div>
            <input
              type="password"
              class="input-text"
              v-model="password"
              :placeholder="$t('register.form.password')"
              data-cy="password"
            />
            <i id="passwordTooltip" class="fa fa-info-circle iconInfo"></i>
            <CpTooltipDynamic
              target="passwordTooltip"
              title="passwordTooltip"
              paddingContainer="10px 5px 10px 5px"
              :message="$t('register.form.toolTipPassword')"
              :width="300"
              :height="100"
            />
            <span v-if="password" class="input__count" :class="countPasswordClass">({{ password.length || 0 }})</span>
            <div class="error" v-if="!goodPassword && registerPressed">
              {{ $t("register.form.goodPassword1") }} <br />
              {{ $t("register.form.goodPassword2") }} <br />
              {{ $t("register.form.goodPassword3") }}
            </div>
            <div>
              <input
                type="password"
                class="input-text"
                v-model="confirmPassword"
                :placeholder="$t('register.form.confirmPassword')"
                data-cy="new-password"
              />
            </div>
            <div
              class="error"
              v-if="!$v.confirmPassword.sameAs && registerPressed"
            >
              {{ $t("register.form.samePassword") }}
            </div>
            <div>
              <select v-model="countrySelected" class="input-select" data-cy="country">
                <option hidden selected :value="null">
                  {{ $t("register.form.selectcountry") }}
                </option>
                <option
                  v-for="(country, key) in countries"
                  v-bind:value="country.value"
                  :key="key"
                >
                  {{ country.text }}
                </option>
              </select>
            </div>
            <div
              class="error"
              v-if="!$v.countrySelected.required && registerPressed"
            >
              {{ $t("register.form.countryRequired") }}
            </div>
            <div style="display: flex">
              <div style="width: 46%">
                <input
                  type="text"
                  class="input-city"
                  v-model="city"
                  :placeholder="$t('register.form.city')"
                   data-cy="city"
                />
                <!-- <div class="error" v-if="!$v.city.required && registerPressed">
                  {{ $t("register.form.cityRequired") }}
                </div> -->
              </div>
              <div>
                <input
                  type="text"
                  class="input-code"
                  v-model="code"
                  :placeholder="$t('register.form.code')"
                   data-cy="code"
                />
                <!-- <div class="error" v-if="!$v.code.required && registerPressed">
                  {{ $t("register.form.codeRequired") }}
                </div> -->
                <div class="error" v-if="!$v.code.integer && registerPressed">
                  {{ $t("register.form.isCodePostalNumber") }}
                </div>
              </div>
            </div>
            <div>
              <select v-model="timezoneSelected" data-cy="timezone" class="input-select">
                <option hidden selected :value="null">
                  {{ $t("register.form.selectzonatime") }}
                </option>
                <option
                  v-for="(timezone, key) in timezones"
                  v-bind:value="timezone.value"
                  :key="key"
                >
                  {{ timezone.text }}
                </option>
              </select>
            </div>
            <div
              class="error"
              v-if="!$v.timezoneSelected.required && registerPressed"
            >
              {{ $t("register.form.timezoneRequired") }}
            </div>
            <div>
              <b-button type="submit" data-cy="submit" class="button-register mt-1">
                <span v-if="loading">
                  <b-spinner class="spinner-btn"></b-spinner>
                  {{ $t("register.form.load") }}
                </span>
                <span v-else>
                  {{ $t("register.form.register") }}
                </span>
              </b-button>
            </div>
          </form>
          <div class="container-bottom-text">
            {{ $t("register.msg1") }}
            <router-link to="/login" class="link-login">{{
              $t("register.link1")
            }}</router-link>
          </div>
        </b-container>
      </div>
    </b-container>
    <IscModalAlerts
      v-model="showAlert"
      :icon="icon"
      :iconColor="iconColor"
      :title="title"
      :paragraph="paragraph"
      :buttonMessage="buttonMessage"
      @sendResult="registerConfirm"
      :noCloseBackdrop="true"
      :refresh="true"
    >
    </IscModalAlerts>
  </div>
</template>
<script>
import { required, email, integer, sameAs, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import {
  AlertSetting,
  IscModalAlerts,
  mapActions,
  CpTooltipDynamic
} from "@isc/styleguide";

export default {
  name: "RegisterContainer",
  mixins: [AlertSetting],
  components: {
    IscModalAlerts,
    CpTooltipDynamic
  },
  data() {
    return {
      idNameGroup: null,
      name: null,
      user: null,
      email: null,
      password: null,
      confirmPassword: null,
      countryData: null,
      countries: [],
      countrySelected: null,
      city: null,
      code: null,
      timezones: [],
      timezoneSelected: null,
      registerPressed: false,
      goodPassword: false,
      goodIdNameGroup: false,
      goodUser: false,
      isRegister: false,
      loading: false
    };
  },
  mounted() {
    this.getAllZonaHoraria();
    this.getAllPais();
    if (window.innerWidth >= 768) {
      const heightContainer = window.innerHeight - 62;
      const $loginContainer = document.querySelector(
        ".container-register-inner"
      );
      $loginContainer.style.height = String(heightContainer) + "px";
    }
  },
  computed: {
    countIdNameGroupClass() {
      return (this.idNameGroup.length < 6 || this.idNameGroup.length > 16) ? 'input__count--error' : null
    },
    countUserClass() {
      return (this.user.length < 6 || this.user.length > 16) ? 'input__count--error' : null
    },
    countPasswordClass() {
      return (this.password.length < 8) ? 'input__count--error' : null
    }
  },
  methods: {
    ...mapActions({
      setUserRegister: "settings/profile/USER_REGISTER",
      getAllZoneHour: "settings/profile/GET_ALL_HOUR_ZONE",
      getAllCountries: "settings/profile/GET_ALL_COUNTRIES",
      getInfoUser: "settings/profile/GET_INFO_USER",
      setSignUp: "login/auth/SIGNUP_CLIENT"
    }),
    async register() {
      this.registerPressed = true;
      // AUTHENTICATION AND LOGIN
      try {
        const isValid = this.validationsRegister();
        if (isValid === 1) return;
        this.loading = true;
        let userLowerCase = this.user.toLowerCase();

        const dataSignUp = {
          username: userLowerCase.trim(),
          email: this.email.trim(),
          password: this.password.trim(),
          idNameGroup: this.idNameGroup.trim(),
          dscName: this.name.trim(),
          idZoneHour: this.timezoneSelected,
          idCountry: this.countrySelected
        };

        await this.setSignUp({ dataSignUp });

        this.name = null;
        this.user = null;
        this.email = null;
        this.password = null;
        this.confirmPassword = null;
        this.countrySelected = null;
        this.city = null;
        this.code = null;
        this.timezoneSelected = null;
        this.registerPressed = false;
        this.isRegister = true;
        this.idNameGroup = null;
        this.successModal("User successfully registered.");
      } catch (err) {
        console.log("[Error] in function register: ", err);
        this.errorModal(err.response.data.message);
      } finally {
        this.loading = false;
      }
    },
    registerConfirm(value) {
      this.showAlert = value;
      if (this.isRegister) {
        window.location.href = "/login/verify";
        this.isRegister = false;
      }
    },
    validationsRegister() {

      if(this.$v.$invalid) return 1;

      try {
        const regexMinusculas = /[a-z]/;
        const regexMayusculas = /[A-Z]/;
        const regexNumeros = /[0-9]/;
        const regexSymbol = /[*@?$!#%&().:_+--]+/;
        const nameValid = ((this.name || "") + " ").trim();
        if (nameValid.length < 1) {
          return 1;
        }

        const regex = /[\p{L}\p{M}\p{S}\p{N}\p{P}]+/gu

        const usernameValid = ((this.user || "") + " ").trim();
        if (usernameValid.length < 1 && regex.test(idNameGroupValid) &&  !/\s/.test(idNameGroupValid)) {
          return 1;
        }
        const emailValid = ((this.email || "") + " ").trim();
        if (emailValid.length < 1) {
          return 1;
        }
        const pwd1Valid = ((this.password || "") + " ").trim();
        if (pwd1Valid.length < 8) {
          this.goodPassword = false;
          return 1;
        }
        if (
          !(
            regexMinusculas.test(pwd1Valid) &&
            regexMayusculas.test(pwd1Valid) &&
            regexNumeros.test(pwd1Valid) &&
            regexSymbol.test(pwd1Valid) &&
            !/\s/.test(newValue)
          )
        ) {
          this.goodPassword = false;
          return 1;
        }
        const pwd2Valid = ((this.confirmPassword || "") + " ").trim();
        if (
          pwd2Valid.length < 8 ||
          !(
            regexMinusculas.test(pwd2Valid) &&
            regexMayusculas.test(pwd2Valid) &&
            regexNumeros.test(pwd2Valid)
          )
        ) {
          return 1;
        }
        if (pwd1Valid !== pwd2Valid) {
          return 1;
        }
        const paisValid = this.countrySelected;
        if (!paisValid) {
          return 1;
        }
        const tipoDatoCodigoPostal = Number(this.code);
        if (isNaN(tipoDatoCodigoPostal)) {
          return 1;
        }
        if (!this.timezoneSelected) {
          return 1;
        }

        const idNameGroupValid = this.idNameGroup.trim()

        if(!(idNameGroupValid.length >= 6 && idNameGroupValid.length <= 16 && regex.test(idNameGroupValid) &&  !/\s/.test(idNameGroupValid))) {
          this.goodIdNameGroup = false;
          return 1;
        }


        return 0;
      } catch (error) {
        console.log("el error en validations: ", error);
        return -1;
      }
    },
    async getAllZonaHoraria() {
      try {
        // NO validation for step of login
        const {
          data: { list }
        } = await this.getAllZoneHour();
        list.forEach(item => {
          this.timezones.push({
            value: item.ID_ZONA_HORARIA,
            text: item.DSC_DESCRIPCION
          });
        });
      } catch (error) {
        this.visibilityLoader = false;
        this.errorModal("Server fails to get all hour zone data.");
        throw Error("Server fails to get all hour zone data.", error);
      }
    },
    async getAllPais() {
      try {
        //* No validation, for step of login
        const {
          data: { list }
        } = await this.getAllCountries();
        list.forEach(item => {
          this.countries.push({
            value: item.ID_PAIS,
            text: item.DSC_DESCRIPCION
          });
        });
      } catch (error) {
        this.visibilityLoader = false;
        this.errorModal("Server fails to get all countries data.");
        throw Error("Server fails to get all countries data.", error);
      }
    },

  },
  watch: {
    password(newValue) {
      if (!newValue) return;
      this.goodPassword =
      this.$v.password.minLength &&
        /[a-z]/.test(newValue) &&
        /[A-Z]/.test(newValue) &&
        /[0-9]/.test(newValue) &&
        !/\s/.test(newValue)
    },
    idNameGroup(newValue) {
      if(!newValue) return;
      
      const regex = /[\p{L}\p{M}\p{S}\p{N}\p{P}]+/gu

      this.goodIdNameGroup =  this.$v.idNameGroup.minLength && this.$v.idNameGroup.maxLength && regex.test(newValue) &&  !/\s/.test(newValue)
    },
    user(newValue) {
      if(!newValue) return;
      
      const regex = /[\p{L}\p{M}\p{S}\p{N}\p{P}]+/gu

      this.goodUser =  this.$v.user.minLength && this.$v.user.maxLength && regex.test(newValue) &&  !/\s/.test(newValue)
    }

  },
  validations: {
    idNameGroup: { 
      required, 
      minLength: minLength(6), 
      maxLength: maxLength(16),
    },
    name: { required },
    user: { 
      required, 
      minLength: minLength(6),
      maxLength: maxLength(16) 
    },
    email: { required, email },
    password: { required, minLength: minLength(8) },
    confirmPassword: { required, sameAs: sameAs("password") },
    countrySelected: { required },
    code: { integer },
    timezoneSelected: { required }
  }
};
</script>
<style scoped>
.container-register-inner {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-register {
  text-align: center;
  font-size: 52.5px;
  font-weight: 200;
}
.subtitle-register {
  text-align: center;
  font-size: 16px;
  font-weight: lighter;
}
.input-text {
  width: 96%;
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
}
.input-text-tooltip {
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
}
.input-select {
  width: 96%;
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
}
.input-city {
  margin-right: 10%;
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
}
.input-code {
  outline: none;
  border: 0;
  border-bottom: 1px solid rgb(137, 137, 137);
  font-size: 14px;
  font-weight: 400;
  padding: 8px 25px;
}
.button-register {
  width: 96%;
  margin-top: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background-color: rgb(0, 206, 193);
  border: none;
  border-radius: 8px;
}
.button-register:hover {
  background-color: rgb(3, 189, 177);
}
.container-bottom-text {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 200;
  text-align: center;
}
.link-login {
  color: black;
}
.error {
  /* margin-top: -16px; */
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  color: #f44336;
}
.iconInfo {
  font-size: 14px;
  color: #5d6b88;
}
.input__count {
  font-size: 12px;
}

.input__count--error {
  color: #f44336;
}
.input__detail {
  font-size: 12px;
  font-style: italic;
  color: #8b8b8b;

}
</style>
<!-- Global style  -->
<style>
.spinner-btn {
  width: 20px;
  height: 20px;
}
</style>
