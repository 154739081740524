var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "container-verify-inner", attrs: { fluid: "" } },
        [
          _c("div", { staticClass: "title-verify" }, [
            _vm._v(_vm._s(_vm.$t("verify.title")))
          ]),
          _c("div", { staticClass: "subtitle-verify" }, [
            _vm._v(_vm._s(_vm.$t("verify.subtitle")))
          ]),
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateCode()
                }
              }
            },
            [
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass: "input-text",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("verify.form.email"),
                    "data-cy": "email"
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                })
              ]),
              !_vm.$v.email.required && _vm.verifyPressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("verify.form.emailRequired")) + " "
                    )
                  ])
                : _vm._e(),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.code,
                      expression: "code"
                    }
                  ],
                  staticClass: "input-text",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("verify.form.code"),
                    "data-cy": "code"
                  },
                  domProps: { value: _vm.code },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.code = $event.target.value
                    }
                  }
                })
              ]),
              !_vm.$v.code.required && _vm.verifyPressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("verify.form.codeRequired")) + " "
                    )
                  ])
                : _vm.$v.code.$invalid && _vm.verifyPressed
                ? _c("div", { staticClass: "error" }, [
                    _vm._v(" " + _vm._s(_vm.$t("verify.form.codeValid")) + " ")
                  ])
                : _vm._e(),
              _c("div", { staticClass: "button-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "button-verify",
                    attrs: { type: "submit", "data-cy": "submit" }
                  },
                  [
                    _vm.loading
                      ? _c(
                          "span",
                          [
                            _c("b-spinner", { staticClass: "spinner-btn" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("register.form.load")) + " "
                            )
                          ],
                          1
                        )
                      : _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("verify.form.check")) + " "
                          )
                        ])
                  ]
                )
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "container-buttom-text" },
            [
              _vm._v(" " + _vm._s(_vm.$t("register.msg1")) + " "),
              _c(
                "router-link",
                {
                  staticClass: "link-bottom",
                  staticStyle: { "text-decoration": "underline" },
                  attrs: { to: "/login" }
                },
                [_vm._v(_vm._s(_vm.$t("register.link1")))]
              )
            ],
            1
          )
        ]
      ),
      _c("IscModalAlerts", {
        attrs: {
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          title: _vm.title,
          paragraph: _vm.paragraph,
          buttonMessage: _vm.buttonMessage,
          noCloseBackdrop: true,
          refresh: true
        },
        on: { sendResult: _vm.verifyConfirm },
        model: {
          value: _vm.showAlert,
          callback: function($$v) {
            _vm.showAlert = $$v
          },
          expression: "showAlert"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }