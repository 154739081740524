import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    navbar: {
      home: "Home",
      ecosystem: {
        title: "Our Ecosystem",
        sections: {
          listen: "Listen",
          monitor: "Monitor",
          design: "Design",
          advice: "Advice"
        }
      },
      contact: "Contact",
      login: "Login",
      register: "Register"
    },
    login: {
      title: "Welcome",
      subtitle: "Enter you data",
      form: {
        idNameGroup: 'ID',
        idNameGroupRequired: 'ID is required',
        user: "User",
        email: "Email",
        password: "Pasword",
        userRequired: "User is required",
        emailRequired: "Email is required",
        passwordRequired: "Password is required",
        login: "Login",
        cargando: "Loading",
        ingresando: "Login to the system"
      },
      msg1: "Do not you have an account yet?",
      link1: "Register now",
      msg2: "Already have a verification code?",
      link2: "Validate your account",
      msg3: "Did you forget your password?",
      link3: "Recover your password"
    },
    register: {
      title: "Register",
      subtitle: "Enter your data",
      form: {
        idNameGroup: 'ID',
        idNameGroupDetail: 'The ID is the identifier with which your work group is recognized on ISC.',
        name: "Name",
        user: "User",
        email: "Email",
        password: "Password",
        confirmPassword: "Confirm Password",
        country: "Choose your country",
        city: "City",
        code: "Postal Code",
        timezone: "Choose your timezone",
        register: "Register",
        nameRequired: "Name is required",
        goodUser1: "The ID must be between 6 and 16 characters,",
        goodUser2: "must not include spaces",
        userRequired: "User is required",
        emailRequired: "Email is required",
        emailValid: "Email is not valid",
        goodIdNameGroup1: "The ID must be between 6 and 16 characters,",
        goodIdNameGroup2: "must not include spaces",
        idNameGroupRequired: 'ID is required',
        goodPassword1:
          "The password must be at least 8 characters and contain a",
        goodPassword2:
          "lowercase character, uppercase character ,a number ",
        goodPassword3:
          "and at least one special character(&,.,#,$,@,-,_).",
        samePassword: "The passwords must be the same",
        countryRequired: "Country is required",
        cityRequired: "City is required",
        codeRequired: "Postal code is required",
        isCodePostalNumber: "Postal code must be a number",
        codeValid: "Postal code is not valid",
        timezoneRequired: "Timezone is required",
        selectcountry: "Select a Country",
        selectzonatime: "Select a Time Zone",
        load: "Loading...",
        toolTipPassword: "The password must be at least 8 characters and contain a lowercase character, uppercase character, a number and at least one special character(&,.,#,$,@,-,_).",
        toolTipIdNameGroup: "The ID must be between 6 and 16 characters, must not include spaces",
        toolTipUser: "The user must be between 6 and 16 characters, must not include spaces"
      },
      msg1: "Do you already have an account?",
      link1: "Login"
    },
    verify: {
      title: "Welcome",
      subtitle: "Enter your code",
      form: {
        user: "User",
        email: "Email",
        code: "Code",
        check: "Check",
        userRequired: "User is required",
        emailRequired: "Email is required",
        codeRequired: "Code is required",
        codeValid: "The code is not valid"
      }
    },
    forgot: {
      sentmail: {
        tittle: "Forgot Password ?",
        subtitle: "Enter your email",
        form: {
          user: "User",
          email: "Email",
          check: "Recover",
          userRequired: "User is required",
          emailRequired: "Email is required",
          confirm: "Check your mail"
        }
      },
      change: {
        tittle: "Change Password",
        subtitle: "Enter your new Password",
        form: {
          code: "Code",
          oldpassword: "Old Password",
          newpassword: "New Password",
          confirmnew: "Confirm New Password",
          codeRequired: "Code is required",
          codeValid: "The code is not valid",
          oldrequired: "Old Password is required",
          newrequired: "New Password is required",
          confirmnewrequired: "Confirm New Password is required",
          goodnewPassword1:
            "The password must be at least 8 characters and contain a",
          goodnewPassword2:
            "lowercase character, uppercase character ,a number ",
          goodnewPassword3:
            "and at least one special character (&,.,#,$,@,-,_).",  
          confirm: "Change Password",
          samePassword: "The new password must be the same",
          toolTipPassword: "The password must be at least 8 characters and contain a lowercase character, uppercase character, a number and at least one special character(&,.,#,$,@,-,_)."
        }
      }
    }
  },
  es: {
    navbar: {
      home: "Inicio",
      ecosystem: {
        title: "Nuestro Ecosistema",
        sections: {
          listen: "Escucha",
          monitor: "Monitor",
          design: "Diseno",
          advice: "Consejo"
        }
      },
      contact: "Contacto",
      login: "Login",
      register: "Registro"
    },
    login: {
      title: "Bienvenido",
      subtitle: "Ingresa tus datos",
      form: {
        idNameGroup: 'ID',
        idNameGroupRequired: 'ID es requerido',
        user: "Usuario",
        email: "Correo electronico",
        password: "Contraseña",
        userRequired: "Usuario es requerido",
        emailRequired: "Correo electronico es requerido",
        passwordRequired: "Contraseña es requerida",
        login: "Ingresar",
        cargando: "Cargando",
        ingresando: "Ingresando al sistema"
      },
      msg1: "Aun no tienes una cuenta?",
      link1: "Registrate ahora",
      msg2: "Ya tienes un codigo de verificacion?",
      link2: "Valida tu cuenta",
      msg3: "¿Olvidaste tu contraseña?",
      link3: "Recupera tu contraseña"
    },
    register: {
      title: "Regístrate",
      subtitle: "Ingresa tus datos",
      form: {
        idNameGroup: 'ID',
        idNameGroupDetail: 'El ID es el identificador con el que se reconoce a tu grupo de trabajo en ISC.',
        name: "Nombre",
        user: "Usuario",
        email: "Correo electronico",
        password: "Contraseña",
        confirmPassword: "Confirmar contraseña",
        country: "Escoja su pais",
        city: "Ciudad",
        code: "Codigo postal",
        timezone: "Escoja su zona horaria",
        register: "Registrar",
        nameRequired: "Nombre es requerido",
        goodIdNameGroup1: "La contraseña debe tener entre 6 y 16 caracteres,",
        goodIdNameGroup2: "no debe incluir espacios",
        idNameGroupRequired: 'ID es requerido',
        goodUser1: "La contraseña debe tener entre 6 y 16 caracteres,",
        goodUser2: "no debe incluir espacios",
        userRequired: "Usuario es requerido",
        emailRequired: "Correo electronico es requerido",
        emailValid: "Email no es valido",
        goodPassword1: "La contraseña debe tener al menos 8 caracteres y",
        goodPassword2: "contener minusculas, mayusculas, numeros y",
        goodPassword3: "al menos un caracter especial (&,.,#,$,@,-,_).",
        samePassword: "La contraseña debe ser la misma",
        countryRequired: "Pais es requerido",   
        cityRequired: "Ciudad es requerida",
        codeRequired: "Código postal es requerido",
        codeValid: "Código postal no es valido",
        isCodePostalNumber: "Código postal debe ser un número",
        timezoneRequired: "Zona horaria es requerida",
        selectcountry: "Seleccione un Pais",
        selectzonatime: "Seleccione una Zona Horaria",
        load: "Cargando...",
        toolTipPassword:"La contraseña debe tener al menos 8 caracteres y contener minusculas, mayusculas, numeros y al menos un caracter especial (&,.,#,$,@,-,_).",
        toolTipIdNameGroup: "El ID debe tener entre 6 y 16 caracteres, no debe incluir espacios",
        toolTipUser: "El usuario debe tener entre 6 y 16 caracteres, no debe incluir espacios"
      },
      msg1: "Ya tienes una cuenta?",
      link1: "Ingresa"
    },
    verify: {
      title: "Bienvenido",
      subtitle: "Ingresa tu codigo",
      form: {
        user: "Usuario",
        email: "Correo electronico",
        code: "Codigo",
        check: "Verificar",
        userRequired: "Usuario es requerido",
        emailRequired: "Correo electronico es requerido",
        codeRequired: "Codigo es requerido",
        codeValid: "El codigo no es valido"
      }
    },
    forgot: {
      sentmail: {
        tittle: "¿Olvidaste tu Contraseña?",
        subttitle: "Ingrese su correo electronico",
        form: {
          user: "Usuario",
          email: "Correo electronico",
          check: "Recuperar",
          userRequired: "Usuario es requerido",
          emailRequired: "Correo electronico es requerido",
          confirm: "Revise su correo"
        }
      },
      change: {
        tittle: "Cambia la contraseña",
        subtitle: "Introduzca su nueva contraseña",
        form: {
          code: "Código",
          oldpassword: "Contraseña anterior",
          newpassword: "Nueva contraseña",
          confirmnew: "Confirmar nueva contraseña",
          codeRequired: "Se requiere código",
          codeValid: "El código no es válido.",
          oldrequired: "Se requiere contraseña antigua",
          newrequired: "Se requiere una nueva contraseña",
          confirmnewrequired: "Se requiere confirmar nueva contraseña",
          goodnewPassword1: "La contraseña debe tener al menos 8 caracteres y",
          goodnewPassword2:
            "contener minusculas, mayusculas ,numeros y al menos un caracter especial(&,.,#,$,@,-,_)",
          confirm: "Cambia la contraseña",
          samePassword: "La nueva contraseña debe ser la misma",
          toolTipPassword:"La contraseña debe tener al menos 8 caracteres y contener minusculas, mayusculas, numeros y al menos un caracter especial (&,.,#,$,@,-,_)."
        }
      }
    }
  }
};

const i18n = new VueI18n({
  locale: window.navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages
});

export default i18n;
